import type { EChartsOption, ECharts } from 'echarts'
import Box from '@mui/material/Box'
import { useEffect, useRef } from 'react'
import { init, getInstanceByDom } from 'echarts'

export type TemperatureProps = {
  title: string
  data: { value: number }[]
}

export default function Temperature(props: TemperatureProps) {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let chart: ECharts
    if (chartRef.current !== null) {
      chart = init(chartRef.current, 'light')
    }
    function resizeChart() {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  useEffect(() => {
    const option: EChartsOption = {
      title: {
        text: props.title
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          splitNumber: 12,
          itemStyle: {
            color: '#FFAB91'
          },
          progress: {
            show: true,
            width: 30
          },
          pointer: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 30
            }
          },
          axisTick: {
            distance: -45,
            splitNumber: 5,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          splitLine: {
            distance: -52,
            length: 14,
            lineStyle: {
              width: 3,
              color: '#999'
            }
          },
          axisLabel: {
            distance: -20,
            color: '#999',
            fontSize: 20
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '-15%'],
            fontSize: 24,
            fontWeight: 'bolder',
            formatter: '{value} °C',
            color: 'inherit'
          },
          data: props.data
        }
      ]
    }
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart?.setOption(option)
    }
  }, [props.data])

  return (
    <Box sx={{ width: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: '100%', minHeight: '300px' }} />
    </Box>
  )
}
